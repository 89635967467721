html {
  --scrollbarBG: rgb(40,40,40);
  --thumbBG: rgb(125,125,125);
  background-color: #333333;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar, .streamDiv::-webkit-scrollbar {
  width: 11px;
}
body::-webkit-scrollbar-track, .streamDiv::-webkit-scrollbar-track{
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb, .streamDiv::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}



body {
  font: 14px "Century Gothic", Futura, sans-serif;
  color: #eeeeee;
  margin: 20px;
}
ol, ul {
margin: 0px;
padding:5px 5px 5px 0px;
}



input[type=text]{
  background-color: #555555;
  color: #eeeeee;
}
#newstreaminput{
  width: 500px;
}

.streamDiv{
  height: 400px;
  overflow-y: scroll;

}

button {
  cursor: pointer;
  background-color: #555555;
  color: #eeeeee;
  margin: 0 1px;
}
button:disabled {
  cursor: not-allowed;
  background-color: #333333;
  color: #555555;
}

.streamlist{
  padding-top: 25px;
  list-style-type:none;
}

.streamlist li {
  display: inline-block;
  padding: 10px;
}

